import api from "api";
import "App.css";
import { useEffect, useState } from "react";
import {
  ConnectType,
  useConnectedWallet,
  useLCDClient,
  useWallet,
  WalletStatus,
} from "@terra-money/wallet-provider";
import { ToastContainer } from "react-toastify";
import {
  burnEvoItem,
  burnItems,
  depositMAG,
  depositMSTR,
} from "./component/terra";
import axios from "axios";
import { NFTType } from "./game-interface";
import close from "./images/close.png";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
// import Countdown from "Countdown";
import Maintain from "Maintain";
import ModalManager from "modal";
import Loading from "component/loading-component";
import Home from "Home";
import { PageComponent } from "component/page-component/page-component";
import { t } from "i18next";
import keplrService from "component/keplr";
const defaultDenom = "uluna";

const miraculousFunc = () =>
  new Promise((resolve, reject) => {
    resolve(null);
  });

function App() {
  useEffect(() => {
    if (isMobile) {
      // @ts-ignore
      document.getElementById("unity-container").classList.add("hidden");
    }
  }, [isMobile]);

  const [page, setPage] = useState("loading");
  // const [startTime, setStartTime] = useState(0);
  const [showLogin, setShowLogin] = useState(false);
  const [size, setSize] = useState({ width: 1280, height: 720 });
  const [gasPrices, setGasPrices] = useState(null);
  const [maintainMessage, setMaintainMessage] = useState("");
  const lcd = useLCDClient();
  const [isLowNetwork, setLowNetwork] = useState(false);
  const [current_client_version, setVersion] = useState(0);
  const {
    status,
    network,
    wallets,
    availableConnectTypes,
    availableInstallTypes,
    availableConnections,
    supportFeatures,
    connect,
    install,
    disconnect,
    signBytes,
  } = useWallet();
  const connectedWallet = useConnectedWallet();
  // @ts-ignore
  const date = new Date(window.getCountDownTime() ?? "2022-08-25T00:00:00Z");

  const getReferralCode = () => {
    let hash = window.location.hash;
    let code = hash.split("code=");
    if (code.length > 1 && code[1]) {
      return code[1];
    } else {
      return null;
    }
  };

  const getGasPrices = async () => {
    await axios.get("https://fcd.terra.dev/v1/txs/gas_prices").then((r) => {
      setGasPrices(r.data);
    });
  };

  useEffect(() => {
    // @ts-ignore
    window.keplrService = keplrService;
    getGasPrices();
    if (date > new Date()) {
      localStorage.clear();
      sessionStorage.clear();
    }
  }, []);

  const handleConnect = async (e: CustomEvent) => {
    connect(ConnectType.EXTENSION);
  };

  const checkLowNetwork = () => {
    var slowLoad = window.setTimeout(function () {
      console.log("load unity slow");
      setLowNetwork(true);
      clearInterval(checkUnityStatus);
    }, 20 * 1000);

    let checkUnityStatus = setInterval(() => {
      // @ts-ignore
      if (window.unityGame) {
        console.log("unity loaded");
        window.clearTimeout(slowLoad);
        clearInterval(checkUnityStatus);
      }
    }, 1000);
  };

  useEffect(() => {
    checkLowNetwork();
  }, []);

  const depositMSTRListener = async (e: CustomEvent) => {
    if (!(connectedWallet && gasPrices)) {
      await miraculousFunc();
      document.dispatchEvent(
        new CustomEvent(`getWalletTerra.${e.detail.eventId}`, {
          detail: null,
        })
      );
      return;
    }
    const amount = e.detail.amount;
    const denom = e.detail.denom || defaultDenom;
    // @ts-ignore
    const result = await depositMSTR(lcd, connectedWallet, amount, {
      denom,
      price: gasPrices[denom],
    });
    document.dispatchEvent(
      new CustomEvent(`depositedTerraMSTR.${e.detail.eventId}`, {
        detail: result,
      })
    );
  };

  const depositMAGListener = async (e: CustomEvent) => {
    if (!(connectedWallet && gasPrices)) {
      await miraculousFunc();
      document.dispatchEvent(
        new CustomEvent(`getWalletTerra.${e.detail.eventId}`, {
          detail: null,
        })
      );
      return;
    }
    const amount = e.detail.amount;
    const denom = e.detail.denom || defaultDenom;
    // @ts-ignore
    const result = await depositMAG(lcd, connectedWallet, amount, {
      denom,
      price: gasPrices[denom],
    });
    document.dispatchEvent(
      new CustomEvent(`depositedTerraMAG.${e.detail.eventId}`, {
        detail: result,
      })
    );
  };

  const burnItemsListener = async (e: CustomEvent) => {
    if (!(connectedWallet && gasPrices)) {
      await miraculousFunc();
      document.dispatchEvent(
        new CustomEvent(`getWalletTerra.${e.detail.eventId}`, {
          detail: null,
        })
      );
      return;
    }
    const itemIds = e.detail.itemIds.map((i: any) => i.toString());
    const type = e.detail.type;
    const denom = e.detail.denom || defaultDenom;
    const result = await burnItems(lcd, connectedWallet, itemIds, type, {
      denom,
      price: gasPrices[denom],
    });
    document.dispatchEvent(
      new CustomEvent(`burnTerraItems.${e.detail.eventId}`, {
        detail: result,
      })
    );
  };

  const burnEvoItemListener = async (e: CustomEvent) => {
    if (!(connectedWallet && gasPrices)) {
      await miraculousFunc();
      document.dispatchEvent(
        new CustomEvent(`getWalletTerra.${e.detail.eventId}`, {
          detail: null,
        })
      );
      return;
    }
    const amount = e.detail.amount;
    // const type = e.detail.type;
    const type = NFTType.EvolutionItem;
    const denom = e.detail.denom || defaultDenom;
    const result = await burnEvoItem(lcd, connectedWallet, amount, type, {
      denom,
      price: gasPrices[denom],
    });
    document.dispatchEvent(
      new CustomEvent(`burnTerraEvolutionItem.${e.detail.eventId}`, {
        detail: result,
      })
    );
  };

  const getBalanceMSTRListener = async (e: CustomEvent) => {
    if (!connectedWallet) {
      await miraculousFunc();
      document.dispatchEvent(
        new CustomEvent(`getWalletTerra.${e.detail.eventId}`, {
          detail: null,
        })
      );
      return;
    }
    const result = await lcd.wasm.contractQuery(
      process.env.REACT_APP_TERRA_MSTR_ADDRESS,
      {
        balance: {
          address: connectedWallet.walletAddress,
        },
      }
    );
    document.dispatchEvent(
      new CustomEvent(`getBalanceTerraMSTR.${e.detail.eventId}`, {
        detail: result,
      })
    );
  };

  const getBalanceMAGListener = async (e: CustomEvent) => {
    if (!connectedWallet) {
      await miraculousFunc();
      document.dispatchEvent(
        new CustomEvent(`getWalletTerra.${e.detail.eventId}`, {
          detail: null,
        })
      );
      return;
    }
    const result = await lcd.wasm.contractQuery(
      process.env.REACT_APP_TERRA_MAG_ADDRESS,
      {
        balance: {
          address: connectedWallet.walletAddress,
        },
      }
    );
    document.dispatchEvent(
      new CustomEvent(`getBalanceTerraMAG.${e.detail.eventId}`, {
        detail: result,
      })
    );
  };

  const getWalletListener = async (e: CustomEvent) => {
    if (!connectedWallet) {
      await miraculousFunc();
      document.dispatchEvent(
        new CustomEvent(`getWalletTerra.${e.detail.eventId}`, {
          detail: null,
        })
      );
      return;
    }

    // TODO: replace this miraculous function
    await miraculousFunc();

    document.dispatchEvent(
      new CustomEvent(`getWalletTerra.${e.detail.eventId}`, {
        detail: connectedWallet.walletAddress,
      })
    );
  };

  useEffect(() => {
    if (status === WalletStatus.WALLET_NOT_CONNECTED) {
      try {
        connect(ConnectType.EXTENSION);
      } catch (error) { }
    }
  }, []);

  useEffect(() => {
    if (connectedWallet) {
      document.dispatchEvent(
        new CustomEvent(`connected`, {
          detail: {
            network,
          },
        })
      );
    }
  }, [connectedWallet]);

  useEffect(() => {
    // @ts-ignore
    setSize(window.getGameSize());
    window.addEventListener("resize", function (event) {
      // @ts-ignore
      setSize(window.getGameSize());
    });

    async function checkClientVersion() {
      const key = "current_client_version";
      let currentVersion = sessionStorage.getItem(key);

      function clearCache() {
        window.indexedDB.databases().then((dbs) => {
          var promises = dbs.map((db) => {
            return new Promise((resolve, reject) => {
              var req = indexedDB.deleteDatabase(db.name);
              req.onsuccess = resolve;
              req.onerror = reject;
              req.onblocked = reject;
            });
          });
          Promise.all(promises).then(console.log).catch(console.error);
        });
      }

      let rs = await api.post("/config/get-client-version", {});
      setVersion(rs.web);
      if (!currentVersion) {
        sessionStorage.setItem(key, rs.web);
      } else {
        if (Number(currentVersion) < rs.web) {
          clearCache();
          sessionStorage.setItem(key, rs.web);
          window.location.reload();
        } else {
          console.log("client is lastest");
        }
      }
      if (rs.maintainMessage) {
        setMaintainMessage(rs.maintainMessage);
      }
      // if (rs.countDown > new Date().getTime()) {
      //   setPage("countdown");
      //   setStartTime(rs.countDown);
      // } else {
      // }
      setPage("home");
    }

    async function getCustomerInfo() {
      let token = sessionStorage.getItem("token");
      if (token) {
        try {
          // @ts-ignore
          let version = window.getVersion();
          console.log({ version });
          version = version.web;
          await api.post("/customer/get-customer-info", {}, version);
        } catch (error) {
          setShowLogin(true);
          sessionStorage.clear();
        }
      } else {
        setShowLogin(true);
      }
    }
    getCustomerInfo();

    checkClientVersion();
    window.document.addEventListener("checkLogin", () => {
      getCustomerInfo();
    });
  }, []);

  function onChatClick() {
    let search = window.location.href.split("?")[1];
    let query = new URLSearchParams(search);
    let lang = query.get("lang");
    if (lang && lang.toLowerCase() === "vn") {
      window.open("https://t.me/monsterra_vietnam", "_blank");
    } else {
      window.open("https://t.me/monsterra_official", "_blank");
    }
  }

  useEffect(() => {
    window.document.addEventListener(
      "connect",
      handleConnect as unknown as EventListener
    );
    window.document.addEventListener(
      "depositTerraMSTR",
      depositMSTRListener as unknown as EventListener
    );
    window.document.addEventListener(
      "depositTerraMAG",
      depositMAGListener as unknown as EventListener
    );
    window.document.addEventListener(
      "getBalanceTerraMSTR",
      getBalanceMSTRListener as unknown as EventListener
    );
    window.document.addEventListener(
      "getBalanceTerraMAG",
      getBalanceMAGListener as unknown as EventListener
    );
    window.document.addEventListener(
      "getWalletTerra",
      getWalletListener as unknown as EventListener
    );
    window.document.addEventListener(
      "burnTerraItems",
      burnItemsListener as unknown as EventListener
    );
    window.document.addEventListener(
      "burnTerraEvolutionItem",
      burnEvoItemListener as unknown as EventListener
    );
    return () => {
      window.document.removeEventListener(
        "connect",
        handleConnect as unknown as EventListener
      );
      window.document.removeEventListener(
        "depositTerraMSTR",
        depositMSTRListener as unknown as EventListener
      );
      window.document.removeEventListener(
        "depositTerraMAG",
        depositMAGListener as unknown as EventListener
      );
      window.document.removeEventListener(
        "getBalanceTerraMSTR",
        getBalanceMSTRListener as unknown as EventListener
      );
      window.document.removeEventListener(
        "getBalanceTerraMAG",
        getBalanceMAGListener as unknown as EventListener
      );
      window.document.removeEventListener(
        "getWalletTerra",
        getWalletListener as unknown as EventListener
      );
      window.document.removeEventListener(
        "burnTerraItems",
        burnItemsListener as unknown as EventListener
      );
      window.document.removeEventListener(
        "burnTerraEvolutionItem",
        burnEvoItemListener as unknown as EventListener
      );
    };
  }, [connectedWallet]);
  return (
    <div className="w-screen h-screen fixed top-0 left-0 z-10 flex items-center justify-center overflow-hidden">
      <ToastContainer hideProgressBar />
      <BrowserView>
        <div
          className="bg-white flex items-center justify-center bg-center bg-cover relative bg-cover"
          style={{
            backgroundImage: `url(splash_celest.jpg)`,
            // width: size.width,
            // height: size.height,
            width: "100vw",
            height: "100vh",
          }}
        >
          <div
            className="absolute bottom-4 right-4 cursor-pointer"
            onClick={onChatClick}
          >
            <img src="/assets/images/chat_new.png" className="w-64" />
            <p className="absolute top-7 left-6 text-white opacity-100 text-[16px]">
              Chat with
              <br />
              other players!
            </p>
          </div>
          <ModalManager
            setShowLogin={setShowLogin}
            referral={getReferralCode()}
          />
          {/* <img src="/assets/images/birthday-mongen.png" alt="logo" className="bottom-0 left-0 absolute" style={{
            width: "20vw"
          }} /> */}
          <div className="header-menu gap-2">
            <img
              src="/assets/images/slider.png"
              alt="logo"
              className="w-[97px] top-2 left-4 cursor-pointer"
              onClick={() => {
                // @ts-ignore
                window.open(window.getLandingPage(), "_blank");
              }}
            />
          </div>
          <div className="header-menu gap-2">
            <div className="">
              <PageComponent
                SSO_META={
                  // @ts-ignore
                  window.getMetaSSO()
                }
              />
            </div>
          </div>
          {
            // @ts-ignore
            !window.isProductionMode() && (
              <div className="absolute top-1 text-[#F40005] alert-testnet py-2 w-3/5 text-center text-sm lg:text-2xl">
                {t("testnet_version_alert")}
                <span
                  className="text-[#243CFF] cursor-pointer"
                  onClick={() => {
                    // @ts-ignore
                    window.open(window.getGuideLink(), "_blank");
                  }}
                >
                  {t("details")}
                </span>
              </div>
            )
          }
          {showLogin && (
            <div className="mx-auto text-center">
              {page === "loading" && <Loading />}
              {/* {page === "countdown" && (
                <Countdown
                  time={startTime}
                  onFinish={() => {
                    setPage("home");
                  }}
                />
              )} */}
              {page === "maintain" && <Maintain message={maintainMessage} />}
              {page === "home" && (
                <Home
                  maintainMessage={maintainMessage}
                  setShowLogin={setShowLogin}
                  version={current_client_version}
                />
              )}

              <div className="h-8"></div>
            </div>
          )}

          <div className="absolute bottom-[4vh] auto-scale flex gap-4">
            <div>
              <p className="text-outline text-center mb-[1vh] text-sm lg:text-xl">
                {t("coming_soon")}
              </p>
              <div className="flex justify-center gap-4 ">
                <img
                  src="/assets/images/download/icon_desktop.png"
                  alt="desktop"
                  className="max-h-[52px] h-[6vh] opacity-70"
                />
              </div>
            </div>

            <div>
              <p className="text-outline text-center mb-[1vh] text-sm lg:text-xl">
                {t("Available in")}
              </p>
              <div className="flex justify-center gap-4 ">
                <img
                  src="/assets/images/download/icon_apple.png"
                  alt="appstore"
                  className="max-h-[52px] h-[6vh] cursor-pointer"
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/monsterra-nft-game/id6444850383",
                      "_blank"
                    );
                  }}
                />
                <img
                  src="/assets/images/download/icon_google.png"
                  alt="google play"
                  className="max-h-[52px] h-[6vh] cursor-pointer"
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.crescentshine.monsterra&pli=1",
                      "_blank"
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div
            id="myProgress"
            className="meter"
            style={{
              opacity: showLogin ? "0" : "100",
            }}
          >
            <div id="bar-text"></div>
            <span id="custom-bar"></span>
          </div>

          {!showLogin &&
            isLowNetwork &&
            // @ts-ignore
            window.location.origin === window.getGameSite() && (
              <div className="absolute left-[2vw] bottom-[3vh] w-[130px]">
                <div
                  className="p-2 mt-4 w-full flex items-center cursor-pointer rounded-lg green-button"
                  onClick={() => {
                    //  @ts-ignore
                    window.onOpenLiteVersion();
                  }}
                >
                  <p className="w-full text-center text-sm">
                    <p className="text-outline">{t("lite_version")}</p>
                  </p>
                </div>
                <div className="text-sm text-center mt-1">
                  {t("lite_version_feature")}
                </div>
              </div>
            )}
        </div>
      </BrowserView>
      <MobileView>
        <MobileSection />
      </MobileView>
    </div>
  );
}

const MobileSection = () => {
  function checkOS() {
    // @ts-ignore
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }
  useEffect(() => {
    console.log("check", checkOS());
    if (checkOS() === "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.crescentshine.monsterra&pli=1";
    } else {
      window.location.href =
        "https://apps.apple.com/us/app/monsterra-nft-game/id6444850383";
    }
    // console.log("mobile")
    // window.open("https://play.google.com/store/apps/details?id=com.crescentshine.monsterra&pli=1", "_system")
  }, []);

  return (
    <div className="p-4">
      <div
        className="bg-[#22253a] p-4 text-white rounded-xl overflow-hidden w-full"
        style={{ paddingBottom: 25 }}
      >
        <div className="flex">
          <img src={close} className="w-[50px] h-[50px] mr-4" />
          <div className="text-sm">
            <p>
              {t("not_supported_mobile")}
              <br />
              {t("retry_on_pc")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
